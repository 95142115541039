//weather details on topBar
// Toggle show weather details
// document.addEventListener("DOMContentLoaded", function () {
//     $(document).ready(function () {
//         $('[data-submit-tooltip]').click(function(e) {
//             e.preventDefault();
//             $(this).next('[data-content-tooltip]').fadeIn();
//         });

//         $('[data-close-tooltip]').click(function() {
//             $(this).closest('[data-content-tooltip]').fadeOut();
//         });
//     });
// });


document.addEventListener("DOMContentLoaded", function () {
    $(document).ready(function () {
        // Kliknięcie w przycisk otwierający tooltip
        $('[data-submit-tooltip]').click(function(e) {
            e.preventDefault();

            // Zamknięcie wszystkich otwartych tooltipów
            $('[data-content-tooltip]').fadeOut().attr('aria-hidden', 'true');
            $('[data-submit-tooltip]').attr('aria-expanded', 'false');
            $("[data-moreItems]").removeClass("open");

            // Otwarcie wybranego tooltipa
            $(this).next('[data-content-tooltip]').fadeIn().attr('aria-hidden', 'false');
            $(this).attr('aria-expanded', 'true');
        });

        // Kliknięcie w przycisk zamykający tooltip
        $('[data-close-tooltip]').click(function() {
            $(this).closest('[data-content-tooltip]').fadeOut().attr('aria-hidden', 'true');
            $(this).closest('[data-content-tooltip]').prev('[data-submit-tooltip]').attr('aria-expanded', 'false');
        });
    });
});